<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Claim No."
                v-model="value.claimNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Display Name"
                v-model="value.displayName"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedCreationDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="modelConfig">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="From Date"
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <div class="control">
              <label class="label">Task Status</label>
              <div class="select"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="value.taskStatus">
                  <option selected="selected"
                    value="open">Open</option>
                  <option value="closed">Closed</option>
                  <option value="all">All</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="label">Task Type</label>
              <div class="select"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="value.taskType">
                  <option selected="selected"
                    value="">All</option>
                  <option value="AudaNet">AudaNet</option>
                  <option value="AudaBridge">AudaBridge</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="label">Job Status</label>
              <div class="select"
                :class="[`select-${$user.info.theme}`]">
                <select v-model="value.businessStatusText">
                  <option selected="selected"
                    value="">All</option>
                  <option value="Authorised">Authorised</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Closed">Closed</option>
                  <option value="Closed - Total Loss">Closed - Total Loss</option>
                  <option value="Created">Created</option>
                  <option value="Modified Supplementary Authorised">Modified Supplementary Authorised</option>
                  <option value="Pending Auth">Pending Auth</option>
                  <option value="Potential Total Loss">Potential Total Loss</option>
                  <option value="Reopened - Supp Added">Reopened - Supp Added</option>
                  <option value="RFQ Received">RFQ Received</option>
                  <option value="Supp Auth. Rejected">Supp Auth. Rejected</option>
                  <option value="Supp Authorised">Supp Authorised</option>
                  <option value="Supp Pending Auth">Supp Pending Auth</option>
                  <option value="Supplementary Added">Supplementary Added</option>
                </select>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'

export default {
  name: 'AudanetTaskListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    value: null
  },
  data() {
    return {
      selectedCreationDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        timeAdjust: '00:00:00'
      }
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedCreationDate(newVal, oldVal) {
      if (newVal) {
        this.value.creationDate = newVal.toISOString().split('.')[0] + 'Z'
      }
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedCreationDate = null
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
